<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Contract
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-card class="card-shadow border-radius-xl">
                  <v-form ref="frm">
                    <v-card-text class="card-padding">
                      <v-row>
                        <v-col cols="12" md="3">
                          <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                              </strong></span>Auction center</label>

                          <v-autocomplete :items="auctionCenters" item-text="auction_center_name" item-value="id"
                            hide-details="auto" v-model="auction_center" @change="resetSeason" class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              select-style
                              mt-2
                            " outlined height="46" :rules="[
                              (v) => !!v || 'Auction center is required',
                            ]" placeholder="Select auction center">
                            <template v-slot:selection="{ item }">
                              <v-text name="c_name" label color="bg-default" class="py-1 px-2 my-0">
                                <span class="text-caption ls-0">{{ item.auction_center_code }}-{{
                                  item.auction_center_name
                                }}</span>
                              </v-text>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                          <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                              </strong></span>Season</label>

                          <v-autocomplete :items="seasons" item-text="name" return-object hide-details="auto"
                            v-model="season" class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              select-style
                              mt-2
                            " outlined height="46" :rules="[(v) => !!v || 'Season is required']"
                            placeholder="Select Season" @change="getSeasonSale">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                          <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                              </strong></span>Sale No</label>

                          <v-autocomplete :items="season_sales" item-text="season_sale" return-object hide-details="auto"
                            v-model="season_sale" class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              select-style
                              mt-2
                            " outlined height="46" :rules="[(v) => !!v || 'Sale No is required']"
                            placeholder="Sale No center">
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="3">
                          <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                              </strong></span>Select file</label>

                          <v-file-input v-model="contract_upload" prepend-inner-icon hide-details="auto" class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                            " dense flat filled solo height="46" placeholder="Upload Contract Note"
                            :rules="[(v) => !!v || 'Choose a file']"></v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-action>
                      <v-row>
                        <v-col class="d-flex justify-end mx-5">
                          <v-btn @click="clear" elevation="0" :ripple="false" height="46" class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-secondary
                              bg-light
                              py-3
                              px-6
                              mt-7
                              mr-2
                            ">Clear</v-btn>

                          <v-btn @click="uploadFile" elevation="0" :ripple="false" height="46" dark class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                              mt-7
                            ">Upload</v-btn></v-col>
                      </v-row>
                    </v-card-action>
                  </v-form>
                </v-card>
              </v-card-text>
            </v-card>
            <v-card class="card-shadow border-radius-xl mt-6" v-show="show_warehouse_Details">
              <v-card-text class="card-padding">
                <v-form ref="frmTable">
                  <v-data-table v-model="resposeData" :headers="headers" :items="resposeData" item-key="master.orderID"
                    mobile-breakpoint="0" :footer-props="{
                      'items-per-page-options': [100, 200, 500, -1],
                    }" fixed-header class="table">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title></v-toolbar-title>

                        <label class="text-md text-typo font-weight-bolder ms-1">Common Warehouse</label>

                        <v-autocomplete :items="wareHouses" item-text="name" return-object hide-details="auto"
                          @change="setCompanyWarehouse()" v-model="common_company_ware_house" class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            ma-2
                            mb-2
                          " outlined height="43" required placeholder="Select Common Warehouse center (Optional)">
                        </v-autocomplete>
                        <v-spacer></v-spacer>
                        <v-btn @click="save" elevation="0" :ripple="false" height="43" dark class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            py-3
                            px-6
                          ">Submit
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.master.sale_date`]="{ item }">
                      {{ dateFormater(item.master.sale_date) }}
                    </template>

                    <template v-slot:[`item.master.company_ware_house`]="{ item }">
                      <v-autocomplete :items="wareHouses" item-text="name" return-object hide-details="auto"
                        v-model="item.master.company_ware_house" class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          select-style
                          mt-2
                          mb-2
                        " outlined height="43" :rules="[(v) => !!v || 'Warehouse is required']" required
                        placeholder="Select Warehouse center">
                      </v-autocomplete>
                    </template>
                  </v-data-table>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col offset-md="5">
                    <v-btn @click="save" elevation="0" :ripple="false" height="43" dark class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                      ">Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="showMissMatchData" persistent max-width="1200">
        <v-card class="card-shadow border-radius-xl">
          <v-card-text>
            <v-data-table :headers="headersMissMatch" :items="missMatchData" mobile-breakpoint="0" fixed-header
              disable-pagination class="table">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Values of Auction Knockdown and ContarctNote are doesn't
                    match.</v-toolbar-title>

                  <v-spacer></v-spacer>
                  <v-btn @click="close" icon elevation="0" :ripple="false" height="28" min-width="36" width="36" class=""
                    color="green">Cancel</v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <BulkVendors v-if="isMissingVendor" :input_data="missingVendors" :dialog_bulk_vendors="dialog_bulk_vendor"
        @close_dialog_bulk_vendors="closeDialogueVendors" @save_dialog_bulk_vendors="saveDialogueVendors"></BulkVendors>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
import BulkVendors from "../../../master/vendors/views/BulkVendors.vue";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  components: {
    BulkVendors,
  },
  data() {
    return {
      overlay: false,
      common_company_ware_house: null,
      show_warehouse_Details: false,
      //
      contract_upload: null,
      seasons: [],
      auctionCenters: [],
      auction_center: null,
      season: null,
      season_sale: null,
      season_sales: [],
      resposeData: [],
      poGenData: [],
      wareHouses: [],
      season_sale_id: "",
      file_name: "",
      missingData: [],
      missingVendors: [],
      isMissingVendor: false,
      dialog_bulk_vendor: false,
      url: "",

      headers: [
        {
          text: "Invoice Date",
          value: "master.sale_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Vendor Name",
          value: "master.auction_vendor.name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Invoice Ref No",
          value: "master.inv_ref_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },


        {
          text: "Company WareHouse",
          value: "master.company_ware_house",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
      ],
      //
      showMissMatchData: false,
      missMatchData: [],
      headersMissMatch: [
        {
          text: "Lot No:",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Auction Knockdown Price",
          value: "reqprice",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },

        {
          text: "Contract Note Price",
          value: "price",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Auction Knockdown Qty",
          value: "reqQty",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },

        {
          text: "Contract Note Qty",
          value: "qty",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    setCompanyWarehouse() {
      if (this.resposeData.length > 0) {
        this.resposeData.map((item) => {
          item.master.company_ware_house = this.common_company_ware_house;
        });
      }
    },
    async initialize() {
      this.overlay = true;
      this.seasons = await api.getSeason();
      this.auctionCenters = await apiMaster.getAuctionCenter();
      this.wareHouses = await api.getWareHouse();
      this.overlay = false;
      this.resposeData = [];
    },
    async getSeasonSale() {
      if (this.auction_center) {
        this.overlay = true;
        this.season_sales =
          await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
            this.season.id,
            this.auction_center
          );
        this.overlay = false;
      }
    },
    async uploadFile() {
      if (this.validate()) {
        let filename = this.contract_upload.name;

        let ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        if (ext == "xlsx") {
          this.resposeData = [];
          this.overlay = true;
          let formData = new FormData();
          formData.append(
            "file",
            this.contract_upload,
            this.contract_upload.name
          );

          formData.append("season_sale_id", this.season_sale.id);

          try {
            await api
              .uplloadFile(formData)
              .then((result) => {
                if (result.status == 201) {
                  this.season_sale_id = result.season_sale_id;
                  this.resposeData = result.data;
                  if (this.resposeData.length > 0) {
                    this.show_warehouse_Details = true;
                  } else {
                    this.show_warehouse_Details = false;
                  }
                  this.showSuccessAlert("Contract Note Uploaded..");
                } else if (result.status == 401) {
                  this.showMissMatchData = true;
                  this.missMatchData = result.data;
                } else {
                  this.isMissingVendor = true;
                  this.missingVendors = result.missing_vendors;
                  this.dialog_bulk_vendor = true;
                  this.url = result.file_name;
                }
              })
              .catch((error) => {
                console.log("error", error);
                this.showErrorAlert(error.message);
              })
              .finally(() => {
                this.overlay = false;
              });
          } catch (error) {
            this.showErrorAlert(error.message);
          }
        } else {
          this.showWarningAlert("Please use .xlsx file ");
        }
      }
    },
    validate() {
      return this.$refs.frm.validate();
    },
    validateTable() {
      return this.$refs.frmTable.validate();
    },
    async save() {
      if (this.validateTable()) {
        if (this.resposeData.length > 0) {
          this.overlay = true;
          try {
            let result = await api.addCompanyWareHouse({
              data: this.resposeData,

              season_sale_id: this.season_sale_id,
              season: this.season,
              // auction_center_id: this.auction_center,
            });
            this.showSuccessAlert("Contract Note Uploaded And PO Created...");
          } catch (error) {
            this.showErrorAlert("Odoo Server error");
          }

          this.overlay = false;
          this.initialize();
          this.show_warehouse_Details = false;
          this.common_company_ware_house = null;
          this.$refs.frm.reset();
        } else {
          this.showWarningAlert("No Data to Save");
        }
      }
    },
    clear() {
      this.contract_upload = null;
      this.auction_center = null;
      this.season_sale = null;
      this.season = null;
      this.resposeData = [];
      this.$refs.frm.resetValidation();
      this.show_warehouse_Details = false;
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        text: message,
        type: "error",

        showConfirmButton: true,
        icon: "error",
      });
    },
    async saveDialogueVendors() {
      console.log("Resending");
      this.overlay = true;
      this.isMissingVendor = false;
      this.dialog_bulk_vendor = false;
      let result = await api.resend({
        season_sale_id: this.season_sale.id,
        url: this.url,
      });
      console.log("Result", result);
      if (result.status == 201) {
        this.season_sale_id = result.season_sale_id;
        this.resposeData = result.data;
        if (this.resposeData.length > 0) {
          this.show_warehouse_Details = true;
        } else {
          this.show_warehouse_Details = false;
        }
        this.showSuccessAlert("Contract Note Uploaded..");
      } else {
        this.isMissingVendor = true;
        this.missingVendors = result.missing_vendors;
        this.url = result.file_name;
        // console.log(this.missingVendors);
      }
      this.overlay = false;
    },
    close() {
      this.showMissMatchData = false;
      this.missMatchData = [];
    },
    resetSeason() {
      this.season = 0;
      this.season_sale = 0;
      this.season_sales = [];
    },
    async closeDialogueVendors() {
      this.dialog_bulk_vendor = false;
      this.isMissingVendor = false;
      this.missingVendors = [];
    },

    dateFormater(date) {
      return formatDate.formatDate(date);
    },
  },
};
</script>
