import axios from "axios";

export default {
  async getSeason() {
    return await axios
      .get("/season")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getSeasonSale(id) {
    console.log(id);
    return await axios
      .get(`/season-sale/sale-by-season/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getWareHouse() {
    return await axios
      .get(`/company-warehouse`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async uplloadFile(item) {
    return axios
      .post(`/purchase-auction/upload-file`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async addCompanyWareHouse(item) {
    return axios
      .post(`/purchase-auction/add-company-ware-house`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async resend(item) {
    return axios
      .post(`/purchase-auction/file/resend`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};

//   resend(item) {
//     return axios
//       .post(API + `/resend-file`, item)
//       .then((res) => {
//         return res.data;
//       })
//       .catch((err) => {
//         throw new Error(err.response.data.message);
//       });
//   },

//   delete(id) {
//     return axios
//       .delete(API + `/${id}`)
//       .then((res) => {
//         return res.data;
//       })
//       .catch((err) => {
//         throw new Error(err.response.data.message);
//       });
//   },

//   edit(id, item) {
//     return axios
//       .patch(API + `/${id}`, item)
//       .then((res) => {
//         return res.data;
//       })
//       .catch((err) => {
//         throw new Error(err.response.data.message);
//       });
//   },

//   async getAuctionCenter() {
//     return await axios
//       .get("auction-center")
//       .then((res) => {
//         return res.data;
//       })
//       .catch((err) => {
//         throw new Error(err.response.data.message);
//       });
//   },
// };
